import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TextInput,
  Select,
  SelectItem,
  Checkbox,
  Button,
  Tag,
} from "@carbon/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { businessSchema, business } from "../../onBoard/profileSchema";
import Grid from "@mui/material/Grid";
import apiL from "../../../api/apiList";
export default function BusinessInfoTab({ data, notify }) {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { ...data.business_info },
    resolver: yupResolver(business),
  });
  const onErrors = async (dsa) => dsa;
  const [IndustryList, setIndustryList] = useState([]);
  const [countryList, setcountryList] = useState([]);
  const [currencyList, setcurrencyList] = useState([]);

  async function getIndustry() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({});
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    fetch(localStorage.getItem("server")+`/get_industry_list/`, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        setIndustryList(dsa.result);
      })
      .catch((error) => console.log("error", error));
    fetch(apiL.getCountry, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        setcountryList(dsa.result);
      })
      .catch((error) => console.log("error", error));
    fetch(apiL.getCurrency, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        setcurrencyList(dsa.result);
      })
      .catch((error) => console.log("error", error));
  }
  useEffect(() => {
    setTimeout(() => {
      reset({ ...data.business_info, ...data.personal_info });
    }, 2000);
  }, [data.business_info, data.personal_info]);
  const onSubmit = async (datas) => {

    delete datas["user_code"];
    delete datas["photo"];
    delete datas["company_code"];
    let pid = localStorage.getItem("id");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify(datas);
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    fetch(apiL.updateProfile + pid, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        notify("Succesfully updated");
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    getIndustry();
  }, []);
  return (
    <TabPanel>
      <form
        className="responsive-form"
        onSubmit={handleSubmit(onSubmit, onErrors)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <div className="onbUserProfileBtn">
              <div>
                <Typography variant="h4B" color="neutral.b800">
                  Business Info
                </Typography>
              </div>
              <div style={{ width: "80%" }}>
                <Typography variant="small" color="neutral.b800">
                  Please keep your business information up to date. We
                  recommend updating your information periodically to ensure
                  reporting accuracy.
                </Typography>
              </div>

              <div className="onbBtn">
                <Button kind="primary" size="md" type="submit">
                  Save Changes
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <div className="onPScroll">
              <div className="onpInput">
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText="Business Name"
                    placeholder="Business Name"
                    invalid={errors.business_name ? true : false}
                    invalidText={errors.business_name?.message}
                    {...register("business_name", { required: true })}
                  />
                </div>
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText="Business Type"
                    placeholder="Business Type"
                    invalid={errors.business_type ? true : false}
                    invalidText={errors.business_type?.message}
                    {...register("business_type", { required: true })}
                  />
                </div>
                <div className="onin">
                  <Select
                    id="select-1"
                    defaultValue="placeholder-item"
                    labelText="Business Stage"
                    invalid={errors.business_state ? true : false}
                    invalidText={errors.business_state?.message}
                    {...register("business_state", { required: true })}
                  >
                    <SelectItem
                      disabled
                      hidden
                      value="placeholder-item"
                      text="Business Stage"
                    />
                    <SelectItem value="startup" text="Start-Up" />
                    <SelectItem value="turnaround" text="Turn Around" />
                    <SelectItem value="accelerated" text="Accelerated Growth" />
                    <SelectItem value="realignment" text="Realignment" />
                    <SelectItem
                      value="sustaining-success"
                      text="Sustaining Success"
                    />
                  </Select>
                </div>
                <div className="onin">
                  <Select
                    id="select-1"
                    defaultValue="placeholder-item"
                    labelText="Industry"
                    invalid={errors.industry_id ? true : false}
                    invalidText={errors.industry_id?.message}
                    {...register("industry_id", { required: true })}
                  >
                    <SelectItem
                      disabled
                      hidden
                      value="placeholder-item"
                      text="Industry"
                    />
                    {IndustryList.map((e) => {
                      return <SelectItem value={e.name} text={e.name} />;
                    })}
                  </Select>
                </div>
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText="Website"
                    placeholder="Website"
                    invalid={errors.website ? true : false}
                    invalidText={errors.website?.message}
                    {...register("website", { required: true })}
                  />
                </div>
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText="Address Line 1"
                    placeholder="Address Line 1"
                    invalid={errors.address_line ? true : false}
                    invalidText={errors.address_line?.message}
                    {...register("address_line", { required: true })}
                  />
                </div>
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText="Address Line 2"
                    placeholder="Address Line 2"
                    invalid={errors.address_line_two ? true : false}
                    invalidText={errors.address_line_two?.message}
                    {...register("address_line_two", { required: true })}
                  />
                </div>
                {/* <div className="oninn">
              <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText="City"
                    placeholder="City"
                    invalid={errors.city ? true : false}
                    invalidText={errors.city?.message}
                    {...register("city", { required: true })}
                  />
              </div> */}
                {/* <div className="oninn">
                  <Select
                    id="select-1"
                    defaultValue="placeholder-item"
                    labelText="Country"
                    invalid={errors.country_id ? true : false}
                    invalidText={errors.country_id?.message}
                    {...register("country_id", { required: true })}
                  >
                    <SelectItem
                      disabled
                      hidden
                      value="placeholder-item"
                      text="Country"
                    />
                    {countryList.map((e) => {
                      return <SelectItem value={e.name} text={e.name} />;
                    })}
                  </Select>
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText="Zip Code"
                    placeholder="Zip Code"
                    invalid={errors.zip ? true : false}
                    invalidText={errors.zip?.message}
                    {...register("zip", { required: true })}
                  />
                </div> */}
                {/* <div className="onin">
              <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText="State"
                    placeholder="State"
                    invalid={errors.state_id ? true : false}
                    invalidText={errors.state_id?.message}
                    {...register("state_id", { required: true })}
                  />
              </div> */}
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="number"
                    labelText="Years In Business"
                    placeholder="Years In Business"
                    invalid={errors.business_age ? true : false}
                    invalidText={errors.business_age?.message}
                    {...register("business_age", { required: true })}
                  />
                </div>

                <div className="onin">
                  <Select
                    id="select-1"
                    defaultValue="placeholder-item"
                    labelText="Business Legal Structure"
                    invalid={errors.legal_structure ? true : false}
                    invalidText={errors.legal_structure?.message}
                    {...register("legal_structure", { required: true })}
                  >
                    <SelectItem
                      disabled
                      hidden
                      value="placeholder-item"
                      text="Business Legal Structure"
                    />
                    <SelectItem value="Corporation" text="Corporation" />
                    <SelectItem value="Partnership" text="Partnership" />
                    <SelectItem
                      value="Limited Liability Company"
                      text="Limited Liability Company"
                    />
                    <SelectItem
                      value="Sole Proprietorship"
                      text="Sole Proprietorship"
                    />
                    <SelectItem value="Joint Venture" text="Joint Venture" />
                    <SelectItem value="Other" text="Other" />
                  </Select>
                </div>

                <div className="onin">
                  {/* <div className="on25">
                    <Select
                      id="select-1"
                      defaultValue="placeholder-item"
                      labelText="Currency"
                      invalid={errors.revenue_currency ? true : false}
                      invalidText={errors.revenue_currency?.message}
                      {...register("revenue_currency", { required: true })}
                    >
                      <SelectItem
                        disabled
                        hidden
                        value="placeholder-item"
                        text="Currency"
                      />
                      {currencyList.map((e) => {
                        return <SelectItem value={e.id} text={e.name} />;
                      })}
                    </Select>
                  </div> */}
                  <div className="on75">
                    <Select
                      id="select-1"
                      defaultValue="placeholder-item"
                      labelText="Select Range"
                      invalid={errors.revenue_range ? true : false}
                      invalidText={errors.revenue_range?.message}
                      {...register("revenue_range", { required: true })}
                    >
                      <SelectItem
                        disabled
                        hidden
                        value="placeholder-item"
                        text="Select Range"
                      />
                      <SelectItem
                        value="Less than 500,000"
                        text="Less than 500,000"
                      />
                      <SelectItem
                        value="500,001-1,000,000"
                        text="500,001-1,000,000"
                      />
                      <SelectItem
                        value=" 1,000,001-3,000,000"
                        text=" 1,000,001-3,000,000"
                      />
                      <SelectItem
                        value=" 3,000,001 -5,000,000 "
                        text=" 3,000,001 -5,000,000 "
                      />
                      <SelectItem
                        value=" 5,000,001 -10,000,000"
                        text=" 5,000,001 -10,000,000"
                      />
                      <SelectItem
                        value=" 10,000,001 - 20,000,000 "
                        text=" 10,000,001 - 20,000,000 "
                      />
                      <SelectItem
                        value="More than 20,000,000 "
                        text=" More than 20,000,000"
                      />
                    </Select>
                  </div>
                </div>
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="number"
                    labelText="Employee Number"
                    placeholder="Employee Number"
                    invalid={errors.employee_number ? true : false}
                    invalidText={errors.employee_number?.message}
                    {...register("employee_number", { required: true })}
                  />
                </div>
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="number"
                    labelText="Physical Location"
                    placeholder="Physical Location"
                    invalid={errors.physical_location ? true : false}
                    invalidText={errors.physical_location?.message}
                    {...register("physical_location", { required: true })}
                  />
                </div>
              </div>
              <div className="onbBtn mbls">
                <Button kind="primary" size="md" type="submit">
                  Save Changes
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
    </TabPanel>
  );
}
