import React, { useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import apiL from "../api/apiList";
import {useLocation } from 'react-router-dom';
export default function Success() {
  
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const pid = searchParams.get("pid");
  async function planUpdate() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      plan_id: pid,
      transaction_id: 123456,
      amount: 5000,
      status: "validated",
    });
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    fetch(apiL.makeInvoice, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa.result.invoices) {
          window.open(
            localStorage.getItem("server")+`/my/invoices/` +
              dsa.result.invoices
          );
        }
      })
      .catch((error) => console.log("error", error));
  }

  const location = useLocation();
  const locationKey = useRef(location.key); // Store the location key on initial render

  useEffect(() => {
    // Function to handle redirection
    const handleRedirect = () => {
      navigate('/not-allowed'); // Redirect to another page
    };

    // Compare the current location key with the previous one
    if (location.key !== locationKey.current) {
      locationKey.current = location.key; // Update the location key reference
      // Detect if the back button was used
      if (window.history.state && window.history.state.idx < window.history.state.idx) {
        handleRedirect();
      }
    }
  }, [navigate, location]);
  useEffect(() => {
    const timeouts = setTimeout(() => {
      planUpdate()
    }, 7000);
    const timeout = setTimeout(() => {
      navigate('/portal/plans',{ replace: true }); // Redirect to the specified URL after 3000 milliseconds (3 seconds)
    }, 10000);

    return () => clearTimeout(timeout,timeouts); // Cleanup function to clear the timeout when the component unmounts
  }, [navigate]);
  return (
    <div className="payment-success-container">
    <div>
    <img src="/lgborder.svg" alt="" srcset="" style={{width:'300px'}} />
    </div>
    <br/>
    <br/>
    <FontAwesomeIcon icon={faCheckCircle} className="success-icon" />
    <h1>Payment Successful</h1>
    <br/>
    <p>Thank you for your payment.</p>
    <p>You will be redirected to the plans and billing page within 10 seconds. Please wait.</p>
  </div>
  )
}
