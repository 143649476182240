import React, { useEffect, useContext, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { TextInput, Select, SelectItem, Checkbox, Button } from "@carbon/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { signIn_schema } from "../../Components/signup/signschema";
import apiL, { updateApiUrls } from "../../api/apiList";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { UserContext } from "../../userContext";

export default function SignIn() {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [btnSub, setbtnSub] = useState(false);
  const id = localStorage.getItem("id");
  const [error, seterror] = useState("");
  let [searchParams] = useSearchParams();
  const code = searchParams.get("activation_code") || null;
  let onb = localStorage.getItem("onboard");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signIn_schema),
  });
  const onSubmit = async (data) => {
    setbtnSub(true);
    console.log(data.server);

    if (data.server === "sandbox") {
      localStorage.setItem("s", "Sandbox");
      localStorage.setItem("server", "https://sandbox.panoramamas.com"); // Use full URL
      localStorage.setItem("ds", "panorama-18022024");
      
    } else {
      localStorage.setItem("s", "Production");
      localStorage.setItem("server", "https://production.panoramamas.com"); // Use full URL
      localStorage.setItem("ds", "panorama-latest");
    }
    updateApiUrls();

    console.log("API URLs updated to:", apiL);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let raw;
    code == null
      ? (raw = JSON.stringify({
          jsonrpc: "2.0",
          params: {
            db: localStorage.getItem("ds"),
            login: data.email,
            password: data.password,
          },
        }))
      : (raw = JSON.stringify({
          jsonrpc: "2.0",
          params: {
            db: localStorage.getItem("ds"),
            login: data.email,
            password: data.password,
            activation_code: code,
          },
        }));

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.signin, requestOptions)
      .then(async (response) => {
        let rfa = localStorage.getItem("id");
        let dsa = await response.json();
        if (dsa.result.profile_id !== rfa) {
          localStorage.clear();
        }
        if (dsa.result.db == localStorage.getItem("ds")) {
          localStorage.setItem("id", data.profileCode);
          localStorage.setItem("uid", data.userID);
          setUser({
            id: data.profileCode,
            uid: data.userID,
          });
          navigate("/portal/index");
        } else {
          seterror(dsa.result.error);
          setbtnSub(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div style={{ minHeight: "100vh", minWidth: "100%" }}>
      <Grid container>
        <Grid item xl={5} lg={5} md={12} className="bg">
          <div className="pd">
            <div className="pdt">
              <img src="/Your paragraph text (700 x 1000 px).webp" />
            </div>
          </div>
        </Grid>
        <Grid item xl={7} lg={7} md={12}>
          <div className="cont">
            <img src="/logoFull.svg" alt="image" width={180} height={120} />
            <div className="form">
              {/* <Typography variant="base" color="initial">
								Welcome
							</Typography> */}
              <Typography variant="h4" color="neutral.b600">
                Sign in (Admin Panel)
              </Typography>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="cont1">
                <div className="txtc">
                  <TextInput
                    size="lg"
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText="Email*"
                    placeholder="Email"
                    invalid={errors.email ? true : false}
                    invalidText={errors.email?.message}
                    {...register("email", { required: true })}
                  />
                </div>

                <div className="txtc">
                  <TextInput
                    size="lg"
                    className="txtF"
                    id="text-input-1"
                    type="password"
                    labelText="Password*"
                    placeholder="Password"
                    invalid={errors.password ? true : false}
                    invalidText={errors.password?.message}
                    {...register("password", { required: true })}
                  />
                </div>
                <div className="txtc">
                  <TextInput
                    size="lg"
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText="Profile Code*"
                    placeholder="Profile Code"
                    invalid={errors.profileCode ? true : false}
                    invalidText={errors.profileCode?.message}
                    {...register("profileCode", { required: true })}
                  />
                </div>
                <div className="txtc">
                  <TextInput
                    size="lg"
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText="User ID*"
                    placeholder="User ID"
                    invalid={errors.userID ? true : false}
                    invalidText={errors.userID?.message}
                    {...register("userID", { required: true })}
                  />
                </div>
                <div className="txtc">
                  <Select
                    id="select-1"
                    defaultValue=""
                    labelText={
                      <span>
                        Select Server<span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    invalid={errors.server ? true : false}
                    invalidText={errors.server?.message}
                    {...register("server", { required: true })}
                  >
                    <SelectItem
                      disabled
                      hidden
                      value=""
                      text=" Select Server"
                      selected
                    />
                    <SelectItem value="sandbox" text="Sandbox" />
                    <SelectItem value="production" text="Production" />
                  </Select>
                </div>
                <div style={{ display: "flex", paddingTop: "12px" }}>
                  <div style={{ paddingRight: "8px" }}>
                    <Button type="submit" disabled={btnSub}>
                      Sign in
                    </Button>
                  </div>

                  {/* <div style={{ paddingRight: "8px" }}>
                    <ButtonGL />
                  </div>
                  <div style={{ paddingRight: "8px" }}>
                    <ButtonFB />
                  </div> */}
                </div>
                <div style={{ paddingTop: "8px" }}>
                  <Typography variant="small" color="error.main">
                    {error}
                  </Typography>
                </div>
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
