import * as yup from "yup";

const signIn_schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Please enter email")
    .test(
      "domain-check",
      "Email must be from panoramamas.com domain",
      (value) => value && value.endsWith("@panoramamas.com")
    ),
  password: yup
    .string()
    .required("Please enter password")
    .min(8, "Password must be at least 8 characters")
    .max(32, "Password must be at most 32 characters"),
  userID: yup.string().required("Please enter User ID"),
  profileCode: yup.string().required("Please enter Profile Code"),
  server: yup.string().required("Please enter Server"),
});
export { signIn_schema };
