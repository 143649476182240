import React from "react";
import Typography from "@mui/material/Typography";
import PortalHeader from "./header";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import apiL from "../../api/apiList";
import { useSearchParams } from "react-router-dom";
import ReportNav from "./ReportNav";
import CompareNav from "./CompareNav";
import LayoutNav from "./layoutNav";
import { checkAccess } from "../planFunctions";
export default function Layout({
  children,
  assesID,
  modal,
  aid,
  compType,
  uid,
  server,
  setmodalAddReport,
  setmodalAdd,
  das,
  logOutSes,
  setlogOutSes,
  sessionID,
  setdas,
  viewMode,
}) {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const pid = localStorage.getItem("pid");
  const invited = localStorage.getItem("invited");
  let vd = localStorage.getItem("vd");
  const [attr, setattr] = useState([]);
  const [openMenu, setOpenmenu] = useState();
  const getData = (data) => {
    setOpenmenu(data);
  };
  const { pathname } = useLocation();
  let id = searchParams.get("code");
  const Logout = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };

    fetch(apiL.logout, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();

        navigate("/signin");
      })
      .catch((error) => console.log("error", error));
  };
  function getParamActiveValue(params, paramName) {
    const param = params.find((param) => param.name === paramName);
    if (param) {
      return param.active ? "Access Granted" : "No Access Granted";
    } else {
      return "loading"; // Indicate that the parameter was not found
    }
  }
  // async function performAccessCheck(s) {
  //   try {
  //     const accessResult = await checkAccess(s);
  //     return accessResult
  //   } catch (error) {
  //     console.error("Error retrieving access check:", error);
  //   }
  // }
  async function check() {
    let r = await checkAccess();
    setattr(r);
  }
  useEffect(() => {
    check();
  }, []);

  return (
    <div>
      <div
        className={openMenu ? "PortalLayout PortalLayoutOpen" : "PortalLayout"}
      >
        <div className="Playout">
          <div className="lgSec">
            <div className="lgo">
              <img src="/lgborder.svg" alt="" srcset="" />
            </div>
            <div className="liness"></div>
          </div>
          <div>
            <div className="services">
              {pathname.includes("/portal/report") ? (
                <ReportNav
                  assesID={assesID}
                  modal={modal}
                  getParamActiveValue={getParamActiveValue}
                  attr={attr}
                />
              ) : viewMode ? (
                <CompareNav
                  das={das}
                  setdas={setdas}
                  compType={compType}
                  setOpenmenu={setOpenmenu}
                  getParamActiveValue={getParamActiveValue}
                  attr={attr}
                  sessionID={sessionID}
                />
              ) : (
                <LayoutNav
                  attr={attr}
                  getParamActiveValue={getParamActiveValue}
                  Logout={Logout}
                  logOutSes={logOutSes}
                  setlogOutSes={setlogOutSes}
                />
              )}
            </div>
            {getParamActiveValue(attr, "PDF Downloads") == "Access Granted" &&
            pathname.includes("/portal/report") ? (
              <div className="xyzz">
                <div
                  className="report-card-btn"
                  onClick={async () => {
                    setmodalAddReport(true);
                    var requestOptions = {
                      method: "GET",
                      redirect: "follow",
                    };

                    fetch(
                      `https://server.panoramamas.com/admin/pdf/?id=${aid}&uid=${uid}&server=${server}`,
                      requestOptions
                    )
                      .then((response) => response.json())
                      .then((result) => {
                        window.open(result.url);
                        setmodalAddReport(false);
                      })
                      .catch((error) => console.log("error", error));
                  }}
                >
                  <img src="/download.svg" />
                  <p>Download Report</p>
                </div>
              </div>
            ) : (
              ""
            )}
            {getParamActiveValue(attr, "PDF Downloads") == "Access Granted" &&
            pathname.includes("/portal/compare/") &&viewMode==true? (
              <div className="xyzz">
                <div
                  className="report-card-btn"
                  onClick={async () => {
                    setmodalAddReport(true);
                    var requestOptions = {
                      method: "GET",
                      redirect: "follow",
                    };

                    fetch(
                      `https://server.panoramamas.com/admin/pdf/compare/?id=${sessionID}&server=${localStorage.getItem("s")}`,
                      requestOptions
                    )
                      .then((response) => response.json())
                      .then((result) => {
                        window.open(result.url);
                        setmodalAddReport(false);
                      })
                      .catch((error) => console.log("error", error));
                  }}
                >
                  <img src="/download.svg" />
                  <p>Download Report</p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <PortalHeader getData={getData} />
      {children}
    </div>
  );
}
