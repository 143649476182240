import React, { useEffect, useState } from "react";
import Layout from "../../../Components/Portal/layout";
import HBar from "./HorizontalChart";
import { useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import apiL from "../../../api/apiList";
import { Button } from "@carbon/react";
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Modal,
  SelectItem,
  TextInput,
} from "@carbon/react";
import { useLocation } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import TableCompare from "./TableCompare";
import Select from "react-select";
import Lottie from "react-lottie";
import animationData from "../../../Components/Portal/dashboard/Animation - 1707685579199.json";
import LinearChart from "./LinearChart";
import TeamChart from "./TeamChart";
import LoadingScreen from "../../../loading";
export default function CompareMain() {
  const [loadingBar, setloadingBar] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  let [searchParams] = useSearchParams();
  const [compType, setcompType] = useState("null");
  const [viewMode, setviewMode] = useState(false);
  const [originalData, setoriginalData] = useState([]);
  const [rows, setrows] = useState([]);
  const [rowsT, setrowsT] = useState([]);
  const [allData, setallData] = useState([]);
  const [UserData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [qdata, setqdata] = useState(null);
  const profile = localStorage.getItem("id");
  const dashboard = searchParams.get("dashboard");
  const [teamData, setteamData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [das, setdas] = useState("BUSINESS");
  const [modalAddReport, setmodalAddReport] = useState(false);
  const [sessionID, setsessionID] = useState("");
  const options = [
    { value: "Time-Based-Comparison", label: "Time-Based-Comparison" },
    { value: "User-Based-Comparison", label: "User-Based-Comparison" },
  ];
  function filterItemsWithStateDone(originalData) {
    // Filter original data to include only items with state "done"
    const filteredData = originalData.filter(
      (assessment) => assessment.state === "done"
    );

    // Return the filtered data
    return filteredData;
  }
  function findItemsByNameAndSort(namesArray, originalData) {
    const result = [];

    namesArray.forEach((nameObj) => {
      // Filter original data to find matching names
      const matchedItems = originalData.filter(
        (assessment) => assessment.name === nameObj.value
      );

      // For each matched item, push a new object to the result array
      matchedItems.forEach((item) => {
        result.push({
          id: item.id,
          name: item.name,
          profile_code: item.profile_code,
          email: item.email,
          company: item.company,
          create_date: item.create_date,
          state: item.state,
        });
      });
    });

    // Sort the result array by create_date
    result.sort((a, b) => new Date(a.create_date) - new Date(b.create_date));

    return result;
  }
  function findItemsByIDAndSort(namesArray, originalData) {
    const result = [];

    namesArray.forEach((nameObj) => {
      // Filter original data to find matching names
      const matchedItems = originalData.filter(
        (assessment) => assessment.id === nameObj.value
      );

      // For each matched item, push a new object to the result array
      matchedItems.forEach((item) => {
        result.push({
          id: item.id,
          name: item.name,
          profile_code: item.profile_code,
          email: item.email,
          company: item.company,
          create_date: item.create_date,
          state: item.state,
        });
      });
    });

    // Sort the result array by create_date
    result.sort((a, b) => new Date(a.create_date) - new Date(b.create_date));

    return result;
  }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  function filterAndFormatData(data) {
    // Count occurrences of each name
    const counts = data.reduce((acc, curr) => {
      acc[curr.name] = (acc[curr.name] || 0) + 1;
      return acc;
    }, {});

    // Filter names that are repeated at least twice
    const filteredNames = Object.keys(counts).filter(
      (name) => counts[name] >= 2
    );

    // Collect unique names and format data
    const uniqueFilteredData = [
      ...new Set(
        data
          .filter((assessment) => filteredNames.includes(assessment.name))
          .map((assessment) => assessment.name)
      ),
    ].map((name) => ({
      value: name,
      label: name,
    }));

    return uniqueFilteredData;
  }
  function createArrayFromValues(values) {
    return values.map(({ value }) => value);
  }
  function createLabelValueArray(userProfiles) {
    return userProfiles.map((profile) => ({
      label: `${profile.name} - ${profile.create_date}`,
      value: profile.id,
    }));
  }
  async function getAssesment() {
    let id = localStorage.getItem("id");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({});
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    fetch(apiL.asseslist + id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa.result) {
          let fdata = filterItemsWithStateDone(dsa.result.assessments);
          setoriginalData(fdata);
          let data = createLabelValueArray(fdata);
          let x = filterAndFormatData(fdata);
          setrowsT(x);
          setrows(data);
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        }
      })
      .catch((error) => console.log("error", error));
  }
  async function getData() {
    setLoading(true);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      data: createArrayFromValues(selected),
      dashboard: das,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };
    const raws = JSON.stringify({
      data: findItemsByNameAndSort(selected, originalData),
      dashboard: das,
    });
    const rawss = JSON.stringify({
      data: findItemsByIDAndSort(selected, originalData),
      dashboard: "BUSINESS",
    });

    const requestOptionss = {
      method: "POST",
      headers: myHeaders,
      body: raws,
    };
    const requestOptionsss = {
      method: "POST",
      headers: myHeaders,
      body: rawss,
    };
    setTimeout(() => {
      if (compType.value == "Time-Based-Comparison") {
        fetch(
          `https://server.panoramamas.com/admin/compare/time/?profile=${profile}&server=${localStorage.getItem("s")}&DB=${localStorage.getItem("ds")}`,
          requestOptionss
        )
          .then((response) => response.json())
          .then(async (result) => {
            let p = await result;
            setallData(p);
            setsessionID(p[0]["sessionid"]);
          })
          .catch((error) => console.error(error));
      } else {
        fetch(
          `https://server.panoramamas.com/admin/compare/users/?profile=${profile}&server=${localStorage.getItem("s")}&DB=${localStorage.getItem("ds")}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            setUserData(result);
            setsessionID(result.sessionid);
          })
          .catch((error) => console.error(error));
        fetch(
          `https://server.panoramamas.com/admin/compare/questions/?profile=${profile}&server=${localStorage.getItem("s")}&DB=${localStorage.getItem("ds")}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => setqdata(result))
          .catch((error) => console.error(error));
      }
      fetch(
        `https://server.panoramamas.com/admin/compare/team/?profile=${profile}&server=${localStorage.getItem("s")}&DB=${localStorage.getItem("ds")}`,
        requestOptionsss
      )
        .then((response) => response.json())
        .then((result) => {
          let previousColors = [];
          function getRandomColor() {
            const letters = "0123456789ABCDEF";
            let color;

            do {
              color = "#";
              for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
              }
            } while (previousColors.includes(color)); // Check if color is already used

            previousColors.push(color); // Store the new color
            return color;
          }
          function modifyData(dat) {
            let x = [];

            dat.forEach((element, a) => {
              let c = getRandomColor();
              x.push({
                label: element.name,
                data: element.Blocks,
                borderColor: c,
                backgroundColor: c,
              });
            });
            return x;
          }
          let labels = result[0].datasets;
          let datax = {
            labels,
            datasets: modifyData(result),
          };
          setteamData(datax);
        })
        .catch((error) => console.error(error));

      setLoading(false);
    }, 5000); // 2 seconds delay
  }

  useEffect(() => {
    getAssesment();
    setTimeout(() => {
      setloadingBar(false);
    }, 2000);
  }, []);
  //   useEffect(() => {
  //     if (selected.length == 0) {
  //       setallData({});
  //     }
  //   }, [selected]);
  useEffect(() => {
    getData();
  }, [das]);
  useEffect(() => {
    setSelected([]);
  }, [compType]);

  return (
    <>
      <Layout
        das={das}
        setdas={setdas}
        setallData={setallData}
        viewMode={viewMode}
        compType={compType}
        sessionID={sessionID}
        setmodalAddReport={setmodalAddReport}
      />
      <Modal
        open={modalAddReport}
        className="userAddModal"
        modalHeading="Generating Report"
        primaryButtonText="Cancel"
        // secondaryButtonText="Cancel"
        onRequestClose={() => setmodalAddReport(false)}
        onRequestSubmit={() => setmodalAddReport(false)}
        size="sm"
      >
        <p style={{ marginBottom: "1rem" }}>
          Your report is being generated. Please enable pop-ups to start
          downloading your assessment report. Please wait.
        </p>
      </Modal>
      {loadingBar ? (
        <LoadingScreen />
      ) : (
        <>
          {viewMode ? (
            <div className="ptl">
              <div className="innerPortal bg-grey-plan">
                <div className="report">
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "70vh",
                      }}
                    >
                      <Lottie
                        options={defaultOptions}
                        height={380}
                        width={350}
                      />
                    </div>
                  ) : teamData.length != 0 && das == "Team" ? (
                    <TeamChart da={teamData} />
                  ) : compType.value == "Time-Based-Comparison" &&
                    allData.length !== 0 ? (
                    allData[0]?.Blocks.map((e, i) => (
                      <LinearChart da={allData} das={e.Name} i={i} />
                    ))
                  ) : qdata !== null &&
                    das == "Question Analysis" &&
                    compType.value !== "Time-Based-Comparison" ? (
                    <TableCompare qdata={qdata} />
                  ) : compType.value !== "Time-Based-Comparison" &&
                    das !== "Question Analysis" ? (
                    Object.entries(UserData).map(([key, value, i]) =>
                      key === "sessionid" ? (
                        ""
                      ) : (
                        <HBar data={value} name={key} key={key + i} />
                      )
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="ptl">
              <div className="innerPortal bg-grey-plan">
                <div className="report">
                  <div className="xcgz">
                    <div className="consultCard" style={{ opacity: "1" }}>
                      <div className="headerPlan">
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_1822_11)">
                            <path
                              d="M10 6.25H7.5C6.83696 6.25 6.20107 6.51339 5.73223 6.98223C5.26339 7.45107 5 8.08696 5 8.75V23.75C5 24.413 5.26339 25.0489 5.73223 25.5178C6.20107 25.9866 6.83696 26.25 7.5 26.25H14.6212"
                              stroke="#2461FF"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M22.5 15V8.75C22.5 8.08696 22.2366 7.45107 21.7678 6.98223C21.2989 6.51339 20.663 6.25 20 6.25H17.5"
                              stroke="#2461FF"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M10 6.25C10 5.58696 10.2634 4.95107 10.7322 4.48223C11.2011 4.01339 11.837 3.75 12.5 3.75H15C15.663 3.75 16.2989 4.01339 16.7678 4.48223C17.2366 4.95107 17.5 5.58696 17.5 6.25C17.5 6.91304 17.2366 7.54893 16.7678 8.01777C16.2989 8.48661 15.663 8.75 15 8.75H12.5C11.837 8.75 11.2011 8.48661 10.7322 8.01777C10.2634 7.54893 10 6.91304 10 6.25Z"
                              stroke="#2461FF"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M10 13.75H15"
                              stroke="#2461FF"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M10 18.75H13.75"
                              stroke="#2461FF"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M17.5 21.875C17.5 22.7038 17.8292 23.4987 18.4153 24.0847C19.0013 24.6708 19.7962 25 20.625 25C21.4538 25 22.2487 24.6708 22.8347 24.0847C23.4208 23.4987 23.75 22.7038 23.75 21.875C23.75 21.0462 23.4208 20.2513 22.8347 19.6653C22.2487 19.0792 21.4538 18.75 20.625 18.75C19.7962 18.75 19.0013 19.0792 18.4153 19.6653C17.8292 20.2513 17.5 21.0462 17.5 21.875Z"
                              stroke="#2461FF"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M23.125 24.375L26.25 27.5"
                              stroke="#2461FF"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1822_11">
                              <rect width="30" height="30" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>

                        <Typography variant="small" color="neutral.b800">
                          Compare Reports
                        </Typography>
                      </div>
                      <div className="contentCard2">
                        <p>Please select the type of comparison you want</p>
                        <div className="compCard">
                          <div className="selectSpace">
                            <div>
                              <Select
                                name="colors"
                                value={compType}
                                options={options}
                                onChange={setcompType}
                                className="basic-multi-select"
                                classNamePrefix="select"
                              />
                            </div>
                            {compType.value == "Time-Based-Comparison" ? (
                              <div style={{ paddingTop: "24px" }}>
                                <MultiSelect
                                  options={rowsT}
                                  value={selected}
                                  onChange={setSelected}
                                  labelledBy="Select" // <- for accessiblity
                                  overrideStrings={{
                                    selectSomeItems: "Select Reports",
                                  }} // <- to override strings
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {compType.value == "User-Based-Comparison" ? (
                              <div style={{ paddingTop: "24px" }}>
                                <MultiSelect
                                  options={rows}
                                  value={selected}
                                  onChange={setSelected}
                                  labelledBy="Select" // <- for accessiblity
                                  overrideStrings={{
                                    selectSomeItems: "Select Reports",
                                  }} // <- to override strings
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="lti">
                            <Lottie
                              options={defaultOptions}
                              height={320}
                              width={320}
                            />
                          </div>
                        </div>
                      </div>
                      <Button
                        onClick={() => {
                          getData();
                          setviewMode(true);
                        }}
                        className="btnss vcb"
                        style={{ maxWidth: "100%", width: "100%" }}
                        kind="primary"
                        size="lg"
                        disabled={selected.length == 0 ? true : false}
                      >
                        Compare Now
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
