// Initial empty object
let apiL = {};

export const updateApiUrls = () => {
  const server = localStorage.getItem("server"); // Get the current server value
  apiL = {
    signup: server + `/signup`,
    otp: server + `/api/otp_mail_send`,
    reset: server + `/forget_password`,
    getCurrency: server + `/get_currencies`,
    signin: server + `/web/session/authenticate`,
    getProfile: server + `/get_profile/`,
    updateProfile: server + `/update_profile/`,
    createProfile: server + `/create_profile/`,
    onBoarding: server + `/onboarding_question/`,
    getUser: server + `/get_user_status/`,
    getCountry: server + `/get_country_list/`,
    getIndustry: server + `/get_industry_list/`,
    getConsultant: server + `/get_consultants/`,
    logout: server + `/web/session/destroy`,
    userList: server + `/get_user_list/`,
    remainUser: server + `/remaining_user/`,
    addUser: server + `/add_user/`,
    sendMail: server + `/api/send_email`,
    getPlan: server + `/get_plans/`,
    invoiceList: server + `/get_invoice/`,
    makeInvoice: server + `/make_invoice`,
    getAssesment: server + `/get_assessment/`,
    asseslist: server + `/get_assessment_list/`,
    updatePicture: server + `/update_picture/`,
    createAssesment: server + `/create_assessment/`,
    getDashboardBusiness: server + `/get_dashboard/`,
    changePassword: server + `/change_password`,
    ssl: server + `/payment_initiate/`,
  };
};

// Initialize with default or existing server value
updateApiUrls();

export default apiL;
