import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, TextInput } from "@carbon/react";
import apiL from "../../../api/apiList";
import { UserContext } from "../../../userContext";
import { useNavigate } from "react-router-dom";
import AssesmentCard from "./AssesmentCard";
import PlanCard from "./PlanCard";
import UserCard from "./UserCard";
import Consult from "./Consult";
import HelpCard from "./HelpCard";
import ReportCard from "./reportCard";
import AsessmentCardDash from "./assessmentCard";
import LoaderAnim from "../../Loader";
import LoadingScreen from "../../../loading";
export default function DashboardIndex({ checkPlan, checkValidity }) {
  const { user, setUser } = useContext(UserContext);
  const pid = localStorage.getItem("pid");
  const aid = localStorage.getItem("id");
  const [loadingBar, setloadingBar] = useState(true)
  const [Plan, setPlan] = useState(null);
  const [assid, setassid] = useState("");
  const [asscode, setasscode] = useState("");
  const [planCardState, setPlanCardState] = useState(0);
  const [userCardState, setuserCardState] = useState(0);
  const [consultCardState, setconsultCardState] = useState(0);
  const [assesmentState, setassesmentState] = useState(0);
  const [data, setdata] = useState({});
  const [rows, setrows] = useState([]);
  const [planData, setplanData] = useState([]);
  const [user_remain, setuser_remain] = useState(0);
  let vd = localStorage.getItem("vd");
  let invited = localStorage.getItem("invited");
  let onb = localStorage.getItem("onboard");
  const [assTaken, setassTaken] = useState("");
  const [assDisable, setassDisable] = useState(false);
  const [assRemaining, setassRemaining] = useState(0);
  const [assNdate, setassNdate] = useState(0);
  const today = new Date();
  const [assodate, setassodate] = useState(today);
  const [assTotal, setassTotal] = useState(0);

  const navigate = useNavigate();
  const [modalConfirm, setmodalConfirm] = useState(false);
  const [modalHeader, setmodalHeader] = useState("");
  const [modalparagraph, setmodalparagraph] = useState("");
  const [modalAction, setmodalAction] = useState("");
  const newAssessmentModal = () => {
    if (modalAction === "new") {
      localStorage.removeItem("assesData");
      navigate("/assesment");
      setmodalConfirm(false);
    }
    if (modalAction === "edit") {
      navigate("/assesment");
      setmodalConfirm(false);
    }
    if (modalAction === "first") {
      navigate("/assesment");
      setmodalConfirm(false);
    }
  };
  async function getProfile() {
    var myHeaders = new Headers();
    let id = localStorage.getItem("id");
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({});
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    fetch(apiL.getProfile + id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa?.result?.plan_info.assessment_number==0){
          setassDisable(true)
   
        }
      })
      .catch((error) => console.log("error", error));
  }
  const Logout = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.logout, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        const keysToRemove = [
          "vd",
          "id",
          "pid",
          "uid",
          "image",
          "onboard",
          "name",
          "invited",
        ];

        // Iterate over each key and remove the item from localStorage
        keysToRemove.forEach((key) => {
          localStorage.removeItem(key);
        });
      })
      .catch((error) => console.log("error", error));
  };
  async function getConsultant() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.getConsultant + user.id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (
          dsa.result &&
          dsa.result.message == "This user has no consultant."
        ) {
          setdata({
            profile_code: "JD-1000-BD-0005",
            profile_id: 0,
            remaining_number: 0,
            total_number: 0,
          });
          setconsultCardState(0);
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        } else {
          setconsultCardState(1);
        }

        setdata(dsa.result.consultants[0]);
      })
      .catch((error) => console.log("error", error));
  }
  async function getAssesmentList() {
    let id = localStorage.getItem("id");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({});
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    fetch(apiL.asseslist + id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa.result) {
          let data = dsa?.result?.assessments.length;
          let x = dsa?.result?.assessments.reverse();
          let f = [];
          dsa?.result?.assessments.map((e) => {
            if (aid === e.profile_code) {
              f.push(e);
            }
          });
          if (f.length == 0) {
            setassTaken(0);
          } else {
            setassTaken(f.length);
          }
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        }
      })
      .catch((error) => console.log("error", error));
  }
  async function getAssesment() {
    let asslength = 0;
    let assLstate = "";
    let assLdate = "";
    async function getAssesmentList() {
      let id = localStorage.getItem("id");
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({});
      var requestOptions = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: raw,
        redirect: "follow",
      };
      fetch(apiL.asseslist + id, requestOptions)
        .then(async (response) => {
          let dsa = await response.json();
          if (dsa.result) {
            let data = dsa?.result?.assessments.length;
            let x = dsa?.result?.assessments.reverse();
            asslength = data;
            setassTaken(dsa?.result?.assessments.length);
            assLstate = x[0].state;
            assLdate = x[0].create_date;
            setassid(x[0].id);
            setasscode(x[0].profile_code);
          } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
            navigate("/signin");
          }
        })
        .catch((error) => console.log("error", error));
    }
    await getAssesmentList();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({});
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.getAssesment + user.id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        let pid = localStorage.getItem("pid");
        let vd = localStorage.getItem("vd");
        let onb = localStorage.getItem("onboard");
        const dyta = localStorage.getItem("assesData");
        if (dyta !== null) {
          setassesmentState(3);
        } else if (onb == "false") {
          Logout();
          navigate("/signin");
        } else if (vd == "false") {
          Logout();
          navigate("/signin");
        } else if (pid == "null") {
          setassesmentState(1);
          // Logout()
          // navigate("/signin");
        } else if (dsa.result) {

          if (
            dsa.result.message ===
            "This profile doesn't have any Question Template!"
          ) {
            setassesmentState(2);
          }
          if (dsa.result.length > 0) {
            if ((asslength === 0) & (pid !== "null")) {
              setassesmentState(2);
            } else if ((asslength !== 0) & (pid !== "null")) {
              var date = moment();
              let activeday = 1;
              var currentDate = date.format("YYYY-MM-DD");
              let odate = new Date(assLdate);
              let oodate = new Date(assLdate);
              let cdate = new Date(currentDate);
              const thirtyDaysLater = new Date(assLdate);
              thirtyDaysLater.setDate(thirtyDaysLater.getDate() + activeday);
              oodate.setDate(odate.getDate() + activeday);
              setassNdate(cdate);
              setassodate(oodate);
              if (assLstate === "done") {
                setassesmentState(4);
                if (cdate > thirtyDaysLater) {
                  setassesmentState(2);
                }
              } else if (assLstate === "submit") {
                setassesmentState(4);
              } else if (assLstate === "draft") {
                setassesmentState(3);
              } else {
                setassesmentState(3);
              }
            }
          }
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        }
      })
      .catch((error) => console.log("error", error));
  }
  async function init(){
    let x = await checkPlan()
    setPlan(x)
  }


  useEffect(() => {
    init()
    getProfile();
    getConsultant();
    getAssesmentList()
    getAssesment();
    setTimeout(()=>{

      setloadingBar(false)
    }, 2000)
  }, []);
  return (
    <>
      {/* <LoaderAnim/> */}
{loadingBar? <LoadingScreen/>:      <div className="innerPortal">
        <Modal
          open={modalConfirm}
          className="userAddModal"
          modalHeading={modalHeader}
          primaryButtonText="Confirm"
          secondaryButtonText="Cancel"
          onRequestClose={() => setmodalConfirm(false)}
          onRequestSubmit={() => newAssessmentModal()}
          size="sm"
        >
          <p style={{ marginBottom: "1rem" }}>{modalparagraph}</p>
        </Modal>
        <div className="quarterArea">
          <AsessmentCardDash
            modalHeader={setmodalHeader}
            Plan={Plan}
            modalparagraph={setmodalparagraph}
            modalConfirm={setmodalConfirm}
            modalAction={setmodalAction}
            assTaken={assTaken}
            setassDisable={assDisable}
          />
          <ReportCard data={consultCardState} consultData={data} />
        </div>
        <div className="quarterArea">
          {invited == "true" ? "" : <PlanCard data={Plan} />}

          {pid == "1" || pid == "2" || invited == "true" ? (
            ""
          ) : (
            <Consult data={consultCardState} consultData={data} />
          )}
        </div>
      </div>}
    </>
  );
}
